.app-store__auto{
    position: absolute;
    bottom: 0;
    right: 530px;
    transition: right 2s;
}

@media (max-width: 698px) {
    .app-store__auto{
        position: absolute;
        bottom: 0;
        right: 355px;
        transition: right 2s;
    }
}