.screen-shots-gallery__b2{
    right: calc(50vw - 425px);
    opacity: 1;
}

@media (max-width: 798px) {
    .screen-shots-gallery__b2 {
        right: calc(50vw - 310px);
    }
}

@media (max-width: 698px) {
    .screen-shots-gallery__b2 {
        right: calc(50vw - 167px);
    }
}