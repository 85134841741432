.auto-animated__weel1 {
    position: absolute;
    height: 50px;
    bottom: 0;
    left: 37px;
    transition: transform 0.5s ease-in-out;
    /* добавляем анимацию с использованием CSS-свойства transform */
}

.rotating {
    animation: rotate 2s linear infinite;
    /* добавляем анимацию вращения */
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}