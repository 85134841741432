.app-store__container-buttons-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 9px solid rgb(207,163,11);
    padding: 10px 10px 20px;
    border-radius: 50px;
    min-width: 500px;
    transition: border 1s;
}

@media (max-width: 698px) {
    .app-store__container-buttons-text {
        min-width: 320px;
    }
}