@font-face {
  font-family: 'Montserrat';
  font-weight: 200;
  src: url("./static/Montserrat-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url("./static/Montserrat-Regular.ttf") format("truetype");
}