.app-store__container-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 430px;
}

@media (max-width: 698px) {
    .app-store__container-buttons {
        width: 290px;
    }
}