.screen-shots-gallery {
    position: relative;
    margin: 40px auto 0;
    width: 100%;
    height: 483px;
}

@media (max-width: 798px) {
    .screen-shots-gallery {
        height: 383px;
    }
}

@media (max-width: 598px) {
    .screen-shots-gallery {
        height: 183px;
    }
}