.screen-shots-gallery__b1 {
    right: calc(50vw - 205px);
    opacity: 1;
}

@media (max-width: 798px) {
    .screen-shots-gallery__b1 {
        right: calc(50vw - 155px);
    }
}

@media (max-width: 698px) {
    .screen-shots-gallery__b1 {
        right: calc(50vw - 80px);
    }
}