.button-app-store {
    display: flex;
    flex-direction: row;
    border: 1px solid white;
    padding: 10px;
    border-radius: 10px;
    transition: box-shadow 0.7s;
    width: 190px;
    justify-content: center;
    align-items: center;
    background-color: transparent !important;
}

.button-app-store:hover {
    cursor: pointer;
    box-shadow: 2px 4px 8px rgba(255, 255, 255, 0.8); /* белая тень */
}

@media (max-width: 698px) {
    .button-app-store {
        padding: 5px;
        border-radius: 10px;
        width: 140px;
    }
}