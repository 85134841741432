.privacy-page_subtitle {
    font-size: 18px;
    font-weight: bold;
    color: rgb(63, 63, 63);
    margin: 30px 10px 0;
}

@media (max-width: 768px) {
    .privacy-page_subtitle {
        font-size: 14px;
    }
}