.info {
    margin: 70px 0;
    padding: 0;
    width: 80vw;
    list-style: none;
    opacity: 0;
    transition: 1s;
}

@media (max-width: 798px) {
    .info {
        margin: 30px 0;
        width: 80vw;
    }
}