.screen-shots-gallery__w2{
    right: calc(50vw + 05px);
    opacity: 1;
}

@media (max-width: 798px) {
    .screen-shots-gallery__w2 {
        right: calc(50vw + 0px);
    }
}

@media (max-width: 698px) {
    .screen-shots-gallery__w2 {
        right: calc(50vw + 10px);
    }
}