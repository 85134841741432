.main-page__title {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial;
    font-weight: 500;
    padding: 70px 0 0;
    margin: 0;
    font-size: 30px;
    color: yellow;
}

@media (max-width: 798px) {
    .main-page__title {
        font-size: 25px;
    }
}

@media (max-width: 598px) {
    .main-page__title {
        font-size: 20px;
        padding: 30px 0 0;
    }
}