.privacy-page_text-view {
    max-width: 100%;
    color: rgb(65, 65, 65);
    word-wrap: break-word;
    font-size: 14px;
    margin: 5px 10px 0;
}

@media (max-width: 768px) {
    .privacy-page_text-view  {
        font-size: 12px;
    }
  }