.screen-shots-gallery__img {
    width: 200px;
    opacity: 0;
    margin: 0;
    position: absolute;
    right: calc(50vw - 105px);
    transition: right 1s, opacity 0.3s;
}

@media (max-width: 798px) {
    .screen-shots-gallery__img {
        width: 150px;  
    }
}

@media (max-width: 698px) {
    .screen-shots-gallery__img {
        width: 80px;  
    }
}