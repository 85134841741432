.info__text {
    color: rgba(255, 255, 255, 0.904);
    font-size: 17px;
    font-family: 'Montserrat';
    font-weight: 200;
}

@media (max-width: 598px) {
    .info__text {
        color: rgba(255, 255, 255, 0.685);
        font-size: 12px;
    }
}

