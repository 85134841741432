.not-found-page__subtitle {
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0;
    margin: 0;
}

@media screen and (max-width: 475px) {
    .not-found-page__subtitle {
        font-size: 12px;
        line-height: 14px;
    }
}