.not-found-page__title {
    font-family: 'Inter', Arial, sans-serif;;
    font-size: 140px;
    font-weight: 400;
    line-height: 169px;
    letter-spacing: 0em;
    text-align: center;
    height: 169px;
    margin: 0;
    padding: 0 0 5px;
}

@media screen and (max-width: 475px) {
    .not-found-page__title {
        font-size: 80px;
        line-height: 96px;
        padding: 0 0 10px;
    }
}