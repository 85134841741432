.app-store {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-left: 170vw;
    margin-top: 70px;
    transition: margin-left 2s;
}

@media (max-width: 798px) {
    .app-store {
        margin-left: 250vw;
        margin-top: 40px;
    }
}

@media (max-width: 598px) {
    .app-store {
        margin-left: 350vw;
        margin-top: 30px;
        transition: margin-left 1.6s ease-in;
    }
}