.button-app-store__title {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial;
    font-weight: 500;
    color: white;
    font-size: 20px;
    font-weight: 500;
    padding: 0 0 0 10px;
    margin: 0;
}

@media (max-width: 698px) {
    .button-app-store__title {
        font-size: 12px;
        font-weight: 500;
        padding: 0 0 0 10px;
        margin: 0;
    }
}