.auto-animated__weel2 {
    position: absolute;
    height: 50px;
    bottom: 0;
    right: 73px;
    transition: transform 1s ease-in-out;
    /* добавляем анимацию с использованием CSS-свойства transform */
}

.rotating {
    animation: rotate 1s linear infinite;
    /* добавляем анимацию вращения */
}

@keyframes rotate {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}