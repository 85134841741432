.main-page__subtitle{
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial;
    font-weight: 500;
    padding: 20px 0 0;
    margin: 0;
    font-size: 20px;
    color: yellow;
    text-align: center;
}

@media (max-width: 798px) {
    .main-page__subtitle {
        font-size: 17px;
    }
}

@media (max-width: 598px) {
    .main-page__subtitle {
        font-size: 15px;
    }
}