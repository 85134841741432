body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url(./fonts/inter.css);

@import url(./app.css);

@import url(./components/MainPage/style/mainPage.css);
@import url(./components/MainPage/style/main-page__title.css);
@import url(./components/MainPage/style/main-page__subtitle.css);

@import url(./components/PrivacyPage/style/privacy-page.css);
@import url(./components/PrivacyPage/style/privacy-page_text.css);
@import url(./components/PrivacyPage/style/privacy-page_title.css);
@import url(./components//PrivacyPage/style/privacy-page_subtitle.css);
@import url(./components/PrivacyPage/style/privacy-page_text-view.css);
@import url(./components/PrivacyPage/style/privacy-page-en.css);

@import url(./components/NotFoundPage/style/not-found-page.css);
@import url(./components/NotFoundPage/style/not-found-page__title.css);
@import url(./components/NotFoundPage/style/not-found-page__subtitle.css);
@import url(./components/NotFoundPage/style/not-found-page__content.css);
@import url(./components/NotFoundPage/style/not-found-page__link.css);

@import url(./components/RemoveProfile/style/remove-profile.css);

@import url(./components/ButtonsAppStore/styles/app-store.css);
@import url(./components/ButtonsAppStore/styles/app-store__auto.css);
@import url(./components/ButtonsAppStore/styles/app-store__auto_animated.css);
@import url(./components/ButtonsAppStore/styles/app-store_animated.css);
@import url(./components/ButtonsAppStore/styles/app-store__title.css);
@import url(./components/ButtonsAppStore/styles/app-store__container-buttons-text.css);
@import url(./components/ButtonsAppStore/styles/app-store__container-buttons-text_animated.css);
@import url(./components/ButtonsAppStore/styles/app-store__container-buttons.css);
@import url(./components/ButtonsAppStore/styles/app-store__img-auto.css);

@import url(./components/ButtonAppStore/styles/button-app-store.css);
@import url(./components/ButtonAppStore/styles/button-app-store__img.css);
@import url(./components/ButtonAppStore/styles/button-app-store__title.css);

@import url(./components/AutoAnimated/styles/auto-animated.css);
@import url(./components/AutoAnimated/styles/auto-animated__auto.css);
@import url(./components/AutoAnimated/styles/auto-animated__weel1.css);
@import url(./components/AutoAnimated/styles/auto-animated__weel2.css);
@import url(./components/AutoAnimated/styles/auto-animated__weels.css);
@import url(./components/AutoAnimated/styles/auto-animated__line.css);

@import url(./components/ScreenShotsGallery/styles/screen-shots-gallery.css);
@import url(./components/ScreenShotsGallery/styles/screen-shots-gallery__img.css);
@import url(./components/ScreenShotsGallery/styles/screen-shots-gallery__w1.css);
@import url(./components/ScreenShotsGallery/styles/screen-shots-gallery__w2.css);
@import url(./components/ScreenShotsGallery/styles/screen-shots-gallery__b1.css);
@import url(./components/ScreenShotsGallery/styles/screen-shots-gallery__b2.css);

@import url(./components/Info/styles/info.css);
@import url(./components/Info/styles/info_animated.css);
@import url(./components/Info/styles/info__text.css);
@import url(./components/Info/styles/info__element.css);